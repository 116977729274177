import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PromoteCta from '../components/common/PromoteCta';
import focusImg from '../images/focus-ireland.png';

function AboutFocus() {
  return (
    <Layout>
      <SEO
        keywords={[`focus ireland`, `homelessness`, `focus`, `lone parents`]}
        title='About Focus Ireland'
      />
      <section
        id='about'
        className=' w-full flex flex-wrap md:flex-row md:flex-no-wrap p-8  max-w-4xl py-16 md:py-32 space-x-6'
      >
        <div className='w-full md:w-1/3 '>
          <img src={focusImg} />
        </div>

        <div className=' md:w-2/3     md:px-0'>
          <header className='text-4xl leading-none md:text-6xl font-serif text-left mb-4 mt-4 md:mt-0 '>
            <h2>
              <span className='alt-heading-bg'> About</span> <br></br>Focus
              Ireland
            </h2>
          </header>
          <div className='content  mx-auto space-y-4 md:text-xl'>
            <p>
              {' '}
              Focus Ireland is Ireland&apos;s leading not for profit working to
              prevent people from becoming, remaining or returning to
              homelessness.
            </p>

            <p>
              Focus Ireland is responsible for the Irish contribution to the
              pan-European REGAL project.
            </p>
            <a
              className='underline faith-link '
              href='https://focusireland.ie/'
            >
              Visit the Focus Ireland website to learn more
            </a>
          </div>
        </div>
      </section>

      {/* How We Created Faith */}
      <section className='grid grid-cols-2 w-full'>
        <Link className='col-span-2 md:col-span-1' to='/creating-faith'>
          <div className=' bg-faith-green '>
            <div className='py-16 md:py-32'>
              {' '}
              <h4 className='text-2xl md:text-5xl text-center font-bold text-white'>
                How We Created Faith
              </h4>
            </div>
          </div>
        </Link>
        {/* About Regal */}
        <Link className='col-span-2 md:col-span-1' to='/about-regal'>
          <div className='bg-faith-orange '>
            <div className='py-16 md:py-32'>
              {' '}
              <h5 className='text-2xl md:text-5xl text-center font-bold  text-white'>
                About Regal
              </h5>
            </div>
          </div>
        </Link>
      </section>
      <PromoteCta />
    </Layout>
  );
}

export default AboutFocus;
